@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	font-size: 16px;
	font-family: 'Noto Sans JP', sans-serif;
}

html,
body,
#__next {
	height: 100vh;
	padding: 0;
	margin: 0;
}

* {
	box-sizing: border-box;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	-moz-appearance: textfield;
	margin: 0;
}

abbr[title] {
	text-decoration: unset !important;
}

.Toastify__toast-container {
	z-index: 9999999 !important;
}
